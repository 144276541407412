import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../layouts/Default';
import Section from '../components/Section';
import Content, { HTMLContent } from '../components/Content';

export const CookiesPageTemplate = ({
  content,
  contentComponent,
  isPreview,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <Section
      sx={(theme) => ({
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      })}
      className={'content'}
    >
      {helmet || ''}
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              {
                isPreview
                  ? <PostContent content={content} />
                  : <MDXRenderer>{content}</MDXRenderer>
              }
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

CookiesPageTemplate.propTypes = {
  isPreview: PropTypes.bool,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const CookiesPage = ({ data }) => {
  const {
    mdx: post,
    site: {
      siteMetadata: { title },
    },
  } = data;
  const { body, frontmatter } = post;
  return (
    <Layout>
      <CookiesPageTemplate
        helmet={
          <Helmet title={`Cookies | ${title}`} />
        }
        content={body}
        contentComponent={HTMLContent}
        {...frontmatter}
      />
    </Layout>
  );
};

CookiesPage.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default CookiesPage;

export const pageQuery = graphql`
  query CookiesPageTemplate {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: { templateKey: { eq: "cookies-page" } }) {
      frontmatter {
        title
      }
      body
    }
  }
`;
